import React, { useContext, useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { Link, navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title, Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        accessGranted: 'Access granted!',
        logIntoVirtooally: 'Log into Virtooally',
        enterLoginDetails: 'Enter your login details below.',
        emailAddress: 'Email Address',
        emailAddressRequired: 'Please enter your email!',
        password: 'Password',
        passwordRequired: 'Please enter your password!',
        logIn: 'Log in',
        forgotPassword: 'Forgot your password?',
    },
    de: {
        accessGranted: 'Zutritt gewährt!',
        logIntoVirtooally: 'Bei Virtooally einloggen',
        enterLoginDetails: 'Bitte geben Sie hier Ihre Logindaten ein',
        emailAddress: 'E-Mail-Adresse',
        emailAddressRequired: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        password: 'Passwort',
        passwordRequired: 'Bitte geben Sie Ihr Passwort ein',
        logIn: 'Einloggen',
        forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
    },
});

const LoginPage = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: { emailId: string; password: string }) => {
        try {
            setLoading(true);
            const loginData = await apiRequester.login(values);
            await store.set('token', loginData.token);
            await store.set('refreshToken', loginData.refreshToken);
            await store.set('user', loginData.user);
            context.changeUser(loginData.user!);
            handleSuccess(strings.accessGranted);
            setLoading(false);
            if (loginData.user?.roles?.includes('super-admin')) {
                navigate('/');
            } else {
                const clientId = loginData?.user?.client?._id;
                navigate('/event-list?clientId=' + clientId);
            }
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };
    return (
        <FormPageLayout>
            <div style={{ marginBottom: '2rem' }}>
                <Title level={2} style={{ marginBottom: '0' }}>
                    {strings.logIntoVirtooally}
                </Title>
                <Paragraph>{strings.enterLoginDetails}</Paragraph>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={strings.emailAddress}
                    labelCol={{ span: 24 }}
                    name="emailId"
                    rules={[{ required: true, type: 'email', message: strings.emailAddressRequired }]}
                >
                    <Input style={{ height: '3rem' }} />
                </Form.Item>
                <Form.Item
                    label={strings.password}
                    labelCol={{ span: 24 }}
                    name="password"
                    rules={[{ required: true, message: strings.passwordRequired }]}
                >
                    <Input.Password style={{ height: '3rem' }} />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading} style={{ height: '3rem' }}>
                            {strings.logIn}
                        </Button>
                        <Link to="/forgot-password">{strings.forgotPassword}</Link>
                    </div>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default LoginPage;
